@import url(//fonts.googleapis.com/css?family=Vibur);

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,400;1,300&family=Playfair+Display:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&display=swap');

body {


  font-size: 14px;
  background-color: #000;
  line-height: 1.4;
  color: #ffffff;
  position: relative;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: 400;
}

// @font-face {
//   font-family: "BudmoJiggler-Regular";
//   src: url(../fonts/BudmoJiggler-Regular.eot);
//   src: url("../fonts/BudmoJiggler-Regular.eot?#iefix") format("embedded-opentype"),
//     url(../fonts/BudmoJiggler-Regular.woff2) format("woff2"),
//     url(../fonts/BudmoJiggler-Regular.woff) format("woff"),
//     url(../fonts/BudmoJiggler-Regular.ttf) format("truetype");
//   font-weight: 400;
//   font-style: normal;
//   font-display: swap;
// }


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.web3gamesfix {
  position: fixed;
  top: calc(50% - 160px);
  right: 0px;
  transform: translate(0%, -50%);
  z-index: 999;
  width: 50px;
  background-color: #000000;
  padding: 1px 5px 6px;
  border-radius: 7px 0px 0px 7px;
  box-shadow: inset 2px 0px 11px -3px #ffffff;
}
.web3gamesfix a::before {
  content: "Live";
  color: #ff7171;
  font-size: 12px;
  position: relative;
  top: 0px;
  left: 8px;
  letter-spacing: 0px;
  text-transform: uppercase;
  font-weight: bold;
}
.web3gamesfix a::after {
  content: "Web3";
  color: #ff7171;
  font-size: 12px;
  position: relative;
  top: -2px;
  left: 4px;
  letter-spacing: 0px;
  text-transform: uppercase;
  font-weight: bold;
}
.web3gamesfix a {
  display: block;
  text-decoration: none;
}

.web3gamesfix img {
  display: block;
  width: 100%;
  height: auto;
}
.bgBody {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
}

.flex {
  display: flex;
  // flex-wrap: wrap;
  justify-content: center;
  // margin: 20px auto;
}

.col-6 {
  flex-basis: 0 0 50%;
  max-width: 50%;
}

.col-12 {
  flex-basis: 0 0 100%;
  max-width: 100%;
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   font-family: "BudmoJiggler-Regular";
//   font-weight: 700;
//   line-height: 1;
// }

.btnMU,
.btn-btnMU {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: relative;
  background-color: #000109;
  text-decoration: none;
  border: transparent;
  --cui-btn-active-bg: transparent;
  border: 1px solid #5411a1;
  box-shadow: inset 1px 1px 8px #5411a1;

  // &::after {
  //   content: "";
  //   position: absolute;
  //   top: -4px;
  //   left: -4px;
  //   height: calc(100% + 8px);
  //   width: calc(100% + 8px);
  //   background: linear-gradient(60deg,
  //       #ffb46a,
  //       #ff9984,
  //       #ff84a7,
  //       #f09eff,
  //       #97baff,
  //       #7bedff,
  //       #93fff1,
  //       #a1ffb8);
  //   border-radius: 6px;
  //   z-index: -1;
  //   animation: animatedgradient 0.9s ease alternate infinite;
  //   background-size: 300% 300%;
  // }

  span {
    display: block;
    padding: 0px 12px;
  }

  &:hover {
    background: #050107;
  }
}

.btn-btnMU:nth-child(1) {
  &::after {
    animation: unset;
  }
}

.btn-btnMU:nth-child(2) {
  &::after {
    animation: unset;
  }
}

.btn-btnMU:nth-child(3) {
  &::after {
    animation: unset;
  }
}

.btn-btnMU:nth-child(4) {
  &::after {
    animation: unset;
  }
}

@-webkit-keyframes animatedgradient {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
    opacity: 0;
  }

  100% {
    background-position: 0 50%;
    opacity: 1;
  }
}

@keyframes animatedgradient {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
    opacity: 0;
  }

  100% {
    background-position: 0 50%;
  }
}

.spincoin {
  // width: 25px;
  height: 25px;
  // margin: 0 5px 0px 0;
}

img {
  max-width: 66%;
  height: auto;
  width: auto;
  // margin: 50px;
  margin: 0 auto;
}

a {
  color: #83c8ff;
  outline: none;

  &:hover {
    color: #ffbebe;
  }
}

#root {
  min-height: 100vh;
}

main {
  // padding: 40px 0px;
  width: 80%;
  height: 80%;
  margin: 0 auto;

  section {
    margin-bottom: 50px;

    &:nth-last-child(1) {
      margin-bottom: 0px;
    }
  }
}

// Header Css Start
header {
  position: sticky;
  top: 0px;
  left: 0;
  width: 100%;
  // box-shadow: 0px 0px 70px -30px #8b8b8b;
  // background-color: #020315;
  background-image: linear-gradient(#020315, #02031500 100%);
  z-index: 2;

  .header {
    padding: 0px 0px;
    min-height: auto;
    background-color: transparent;
    border: none;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-color: #020314;
      filter: blur(30px);
    }
  }

  .header-brand {
    img {
      width: 100%;
      max-width: 200px;
    }
  }

  .header-nav {
    a {
      padding: 0px 10px;
      text-transform: uppercase;
      text-decoration: none;

      &.active {
        color: #ffbebe;
      }
    }
  }

  .bgBody {
    position: absolute;
  }

  .logoMainHead {
    width: 140px;
  }
}

.buttonGroup {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;

}

.MuiTableCell-root {
  border-bottom: none !important
}

.MuiTableCell-root {
  border-bottom: none !important;
  border: none !important;
}

.MuiFormControl-root {

  .MuiFormLabel-root,
  .MuiFormLabel-root.Mui-focused {
    color: #fff;
  }

  .MuiOutlinedInput-root.Mui-focused,
  .MuiOutlinedInput-root:hover {
    fieldset {

      border-color: #ffff
    }
  }
}

.MuiInputBase-root {
  fieldset {
    border-color: #ffff;
  }

  input {
    color: #fff;
  }

  .MuiInputBase-input {
    color: #fff;
  }

  .MuiSvgIcon-root {
    color: #fff;
  }
}

.backsidebtn {
  background: #652fa3;
  padding: 6px;
  border-radius: 50%;
  cursor: pointer;
}

/* this is a recreation of twitter search in css */
.form {
  --input-text-color: #fff;
  --input-bg-color: #283542;
  --focus-input-bg-color: transparent;
  --text-color: #949faa;
  --active-color: #652fa3;
  --width-of-input: 200px;
  --inline-padding-of-input: 1.2em;
  --gap: 0.9rem;
}

/* form style */
.form {
  font-size: 0.9rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: var(--width-of-input);
  position: relative;
  isolation: isolate;
  // margin: 0 auto;
}

/* a fancy bg for showing background and border when focus. */
.fancy-bg {
  position: absolute;
  width: 100%;
  inset: 0;
  background: var(--input-bg-color);
  border-radius: 30px;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

/* label styling */
label {
  width: 100%;
  padding: 0.8em;
  height: 40px;
  padding-inline: var(--inline-padding-of-input);
  display: flex;
  align-items: center;
}

.search,
.close-btn {
  position: absolute;
}

/* styling search-icon */
.search {
  fill: var(--text-color);
  left: var(--inline-padding-of-input);
}

/* svg -- size */
svg {
  width: 17px;
  display: block;
}

/* styling of close button */
.close-btn {
  border: none;
  right: var(--inline-padding-of-input);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0.1em;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--active-color);
  opacity: 0;
  visibility: hidden;
}

/* styling of input */
.input {
  color: var(--input-text-color);
  width: 100%;
  margin-inline: min(2em, calc(var(--inline-padding-of-input) + var(--gap)));
  background: none;
  border: none;
}

.input:focus {
  outline: none;
}

.input::placeholder {
  color: var(--text-color)
}

/* input background change in focus */
.input:focus~.fancy-bg {
  border: 1px solid var(--active-color);
  background: var(--focus-input-bg-color);
}

/* search icon color change in focus */
.input:focus~.search {
  fill: var(--active-color);
}

/* showing close button when typing */
.input:valid~.close-btn {
  opacity: 1;
  visibility: visible;
}

/* this is for the default background in input,when selecting autofill options -- you can remove this code if you do not want to override the browser style.  */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

.incometable {
  background-color: #242323 !important;

  button {
    color: #fff !important;
  }

  font-family: "Chakra Petch",
  sans-serif !important;

  td {
    color: #fff !important;
  }

  th {
    color: #fff !important;

  }
}

.incometittle {
  background: #652FA3;
  font-family: "Chakra Petch", sans-serif !important;


  tr {
    th {

      color: #fff !important;
    }
  }
}

// Header Css End

// Footer Css Start
footer {
  position: sticky;
  top: 100%;
  left: 0;
  width: 100%;
  // box-shadow: 0px 0px 70px -30px #8b8b8b;

  .copyright {
    color: #ffffff;
  }

  .footer {
    background-color: transparent;
    border: none;
    padding: 60px 0px 15px;
    position: relative;
    overflow: hidden;
  }

  .social-icons {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      font-size: 20px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #83c8ff;
      border: 1px solid #83c8ff;
      border-radius: 3px;

      &:hover {
        border-color: #ffbebe;
        color: #ffbebe;
      }
    }
  }
}

.footer_bottom {
  padding: 20px 0;
  border-top: 1px solid #404040;
  background-color: #000;
}

.copyright_right {
  p {
    margin-bottom: 0px;
    font-size: 14px;
    color: #aaa6b9;
    font-weight: 500;
    text-transform: uppercase;
  }

  a {
    color: #fff;
    font-weight: 600;
    text-decoration: none;
  }
}

.footer_social {
  ul {
    margin-bottom: 0px;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin-right: 10px;
    padding-right: 20px;
    position: relative;

    &::before {
      position: absolute;
      content: "||";
      right: 0;
      top: 50%;
      transform: translatey(-50%);
    }

    &:last-child::before {
      content: none;
    }
  }

  a {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    text-align: center;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

// Footer Css End

// Banner Css Start
.topInformation {
  margin-bottom: 30px;

  h1 {
    font-size: 52px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    line-height: 1.3;
    font-family: "BudmoJiggler-Regular";
    margin-bottom: 30px;
    text-align: left;
    color: #3c2900;
    text-shadow: 0 0 7px rgba(0, 0, 0, 0), 0 0 0 #fff, 0 0 2px #fff,
      0 0 2px #ffe656, 0 0 2px #ffe656, 0 0 0 #ffe656, 0 0 2px #ffe656,
      0 0 11px #ffe656;

    .nftxt {
      color: #db9eff;
    }
  }

  .btnMU,
  .btn-btnMU {
    font-size: 20px;
    // min-width: 200px;
  }
}


.nftmainimg {
  img {
    max-height: 700px;
  }
}

.listViewBx {
  background-color: #000;
  border-radius: 6px;
  position: relative;
  margin: 20px auto;
  box-shadow: inset 5px 5px 57px #5411a1;
  // &::before {
  //   content: "";
  //   position: absolute;

  //   top: -2px;
  //   left: -2px;
  //   height: calc(100% + 4px);
  //   width: calc(100% + 4px);
  //   background: linear-gradient(60deg,
  //       #ffb46a,
  //       #ff9984,
  //       #ff84a7,
  //       #f09eff,
  //       #97baff,
  //       #7bedff,
  //       #93fff1,
  //       #a1ffb8);
  //   border-radius: 6px;
  //   z-index: -1;
  //   animation: animatedgradient 3s ease alternate infinite;
  //   background-size: 300% 300%;
  // }

  .inrInfoBx {
    text-align: center;
    margin: 0 auto;

    .imginrBx {
      position: relative;
      margin: 0 auto 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }

    p {
      font-size: 19px;
      font-family: "Chakra Petch", sans-serif;
    }

    strong {
      font-size: 31px;
      display: block;
      font-family: "BudmoJiggler-Regular";
    }

    img {
      max-width: 100px;
      margin: 0 auto 0px;
      display: block;
      position: relative;
      animation: zoominout 3s alternate infinite;
    }

    @keyframes zoominout {

      0%,
      100% {
        transform: scale(1) rotate(-5deg);
      }

      50% {
        transform: scale(0.95) rotate(0);
      }
    }
  }
}

.lazyload-wrapper {
  // max-width: 600px;
  width: fit-content;
  margin: 10px auto;

  video {
    transform: scale(1);
    mix-blend-mode: lighten;
  }
}

.lazyload-wrapper svg {
  mix-blend-mode: difference;
}

// Banner Css End

// nft Balance Css Start
.nftBalance {
  .balanceViewBx {
    .balancewrap {
      margin-bottom: 30px;
      border-radius: 0px;
      box-shadow: 0px 0px 100px -80px #ffffff;
      border-radius: 6px;
      height: 100%;
      position: relative;
      display: block;
      text-align: center;
      // background: url(../images/nfts/Lvl1.gif);
      opacity: 1;
      border: 1px solid #020004;
      padding: 20p;
      background-size: contain;
      background-color: #000;

      &::before {
        content: "";
        position: absolute;
        top: -2px;
        left: -2px;
        height: calc(100% + 4px);
        width: calc(100% + 4px);
        border-radius: 6px;
        background-size: 300% 300%;
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(1px);
        content: "";
        position: absolute;
        top: -2px;
        left: -2px;
        height: calc(100% + 4px);
        width: calc(100% + 4px);
        background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
        border-radius: 6px;
        z-index: -1;
        animation: animatedgradient 3s ease alternate infinite;
        background-size: 300% 300%;
      }
    }

    .balancelist:first-child {
      border-radius: 6px 6px 0 0px;
    }

    .balancelist:last-child {
      border-radius: 0px 0px 6px 6px;
    }

    .balancelist {
      position: relative;
      padding: 10px 20px;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      background: rgba(58, 47, 47, 0.23);


      // &::before {
      //   content: "";
      //   position: absolute;
      //   top: -2px;
      //   left: -2px;
      //   height: calc(100% + 4px);
      //   width: calc(100% + 4px);
      //   background: linear-gradient(
      //     60deg,
      //     #ffb46a,
      //     #ff9984,
      //     #ff84a7,
      //     #f09eff,
      //     #97baff,
      //     #7bedff,
      //     #93fff1,
      //     #a1ffb8
      //   );
      //   border-radius: 6px;
      //   z-index: -1;
      //   animation: animatedgradient 3s ease alternate infinite;
      //   background-size: 300% 300%;
      // }

      span {
        font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
        font-size: 16px;
      }

      &:nth-last-child(1) {
        margin-bottom: 0px;
      }

      svg {
        margin-left: 10px;
        width: 22px;
        height: auto;
        display: inline-block;
        cursor: pointer;
      }
    }

    .gifbox {
      max-width: 120px;
      margin: 12px auto;
      z-index: 999;
      position: relative;
      // background: #cf95433d;
      background: rgb(58 47 47 / 46%);
      border-radius: 50%;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

// nft Balance & List Css End

// ournft
.newstake {

  .leftinfo {
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 6px;
  }
}

.nftMain {

  .nftheadline {
    font-size: 42px;
    margin-bottom: 20px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    text-shadow: 0 0 7px #0000, 0 0 0 #fff, 0 0 2px #fff, 0 0 2px #bc13fe,
      0 0 2px #bc13fe, 0 0 0 #bc13fe, 0 0 2px #bc13fe, 0 0 11px #bc13fe;
    animation: pulsate 1.5s infinite alternate;
    font-weight: 400;
  }

  .leftinfo {
    display: flex;
    justify-content: space-between;
    padding: 6px;
    font-family: "Chakra Petch", sans-serif;

    .highlight {
      padding-left: 8px;
      padding-right: 6px;
      border-radius: 4px;
      background: #5411a1;
    }
  }



  .rightinfo {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
  }


  .stakebtnarea {
    position: relative;
    text-align: center;
    margin-bottom: 15px;

    span {
      font-size: 16px;
      font-family: 'Chakra Petch', sans-serif;

      font-weight: 500;
      color: #fff;
      display: block;
    }
  }

  a {
    color: #e3e3e3;
    text-decoration: none;
  }

  .stakebtn {
    // background-color: #676767;
    padding: 5px 16px;
    border-radius: 6px;
    text-decoration: none;
    text-align: center;
    z-index: 9;
    color: white;
    font-weight: bolder;
    text-transform: uppercase;
    position: relative;
    width: 62%;
    margin-bottom: 25px;
    border: 1px solid #5411a1;
    // &::after {
    //   content: "";
    //   position: absolute;
    //   top: -4px;
    //   left: -4px;
    //   height: calc(100% + 8px);
    //   width: calc(100% + 8px);
    //   background: linear-gradient(60deg,
    //       #ffb46a,
    //       #ff9984,
    //       #ff84a7,
    //       #f09eff,
    //       #97baff,
    //       #7bedff,
    //       #93fff1,
    //       #a1ffb8);
    //   border-radius: 6px;
    //   z-index: -1;
    //   background-size: 300% 300%;
    // }
  }

  .popular_gaming_inner {
    .col-3 {
      flex-basis: 0 0 25%;
      max-width: 25%;
    }

    .swiper-button-prev {
      background-color: #873787;
      cursor: pointer;
      height: 50px;
      left: 0;
      position: absolute;
      top: 45%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 50px;
      z-index: 9999999;

      &::before {
        align-items: center;
        content: "↞";
        display: flex;
        font-size: 36px;
        font-weight: 600;
        height: 100%;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    .swiper-button-next {
      background-color: #873787;
      cursor: pointer;
      height: 50px;
      position: absolute;
      right: 0;
      top: 45%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 50px;
      z-index: 9999999;

      &::before {
        align-items: center;
        content: "↠";
        display: flex;
        font-size: 36px;
        font-weight: 600;
        height: 100%;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    .notearea {
      margin: 0 auto;
      padding: 12px;
      text-align: justify;
    }

    .nftsvideo {
      border: 1px solid #686868;
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      border-radius: 8px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      // background-color: #000;
      cursor: pointer;
      -webkit-transform-origin: right bottom;
      -ms-transform-origin: right bottom;
      transform-origin: right bottom;
      -webkit-transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
      transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
      border: 2px solid #5411a1;
      // height: 651px;

      :disabled {
        // background: rgba(101, 47, 163, 0.5);
        box-shadow: none;
        opacity: 0.7;
        // animation: none;

        // &:hover {
        //   background: rgba(101, 47, 163, 0.5);
        //   box-shadow: none;
        // }
      }
    }

    .nftboxtitle {
      align-items: center;
      background-color: #686868;
      color: #fff;
      display: flex;
      font-size: 14px;
      font-weight: 500;
      justify-content: center;
      left: -60px;
      padding: 7px 5px;
      position: absolute;
      text-align: center;
      top: 40px;
      -webkit-transform: rotate(-55deg);
      transform: rotate(-55deg);
      width: 200px;
      z-index: 1;
    }

    .swiper-slide {
      overflow: hidden;
    }
  }
}

.stakebtn {
  // background-color: #676767;
  padding: 5px 5px;
  border-radius: 6px;
  text-decoration: none;
  text-align: center;
  z-index: 9999999;
  color: white;
  font-weight: bolder;
  text-transform: uppercase;
  position: relative;
  font-size: 12px;
  border: 1px solid #5411a1;
  background: transparent;

}

// Connect Wallet css Start
.connectWalletToggle {
  background-color: rgba(0, 0, 0, 0.8);

  .modal-dialog {
    max-width: 450px;
  }

  .modal-content {
    color: #ffffff;
    background-color: #0a0a0a;
    box-shadow: 0px 0px 70px -40px #666666;
    padding: 0px 20px;

    &::before {
      content: "";
      position: absolute;
      top: -2px;
      left: -2px;
      height: calc(100% + 4px);
      width: calc(100% + 4px);
      background: linear-gradient(60deg,
          #ffb46a,
          #ff9984,
          #ff84a7,
          #f09eff,
          #97baff,
          #7bedff,
          #93fff1,
          #a1ffb8);
      border-radius: 6px;
      z-index: -1;
      animation: animatedgradient 3s ease alternate infinite;
      background-size: 300% 300%;
    }

    .modal-header {
      align-items: flex-start;
      border: none;
      padding: 20px 10px;
    }

    .modal-body {
      padding: 10px;
    }

    button.btn-close {
      outline: none;
      box-shadow: none !important;
      opacity: 1;
      filter: invert(1);
    }

    .modal-title {
      font-size: 24px;
      margin-bottom: 0px;
      font-family: "BudmoJiggler-Regular";
      color: yellow;
      text-shadow: 0.3px 0.3px 0.3px #111;
      letter-spacing: 0.11em;
      color: #3c2900;
      text-shadow: 0 0 7px rgba(0, 0, 0, 0), 0 0 0 #fff, 0 0 2px #fff,
        0 0 2px #ffe656, 0 0 2px #ffe656, 0 0 0 #ffe656, 0 0 2px #ffe656,
        0 0 11px #ffe656;

      p {
        font-size: 14px;
        line-height: 1.2;
        font-weight: 400;
        margin: 10px 0px 0px;
        text-shadow: none;
      }
    }
  }

  .popupbtngrp {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .btngrp {
      flex-basis: calc(33.33% - 15px);
      max-width: calc(33.33% - 15px);
      margin: 0px 7px 20px;
      display: block;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: -2px;
        left: -2px;
        height: calc(100% + 4px);
        width: calc(100% + 4px);
        background: linear-gradient(60deg,
            #ffb46a,
            #ff9984,
            #ff84a7,
            #f09eff,
            #97baff,
            #7bedff,
            #93fff1,
            #a1ffb8);
        border-radius: 6px;
        z-index: 0;
        background-size: 300% 300%;
      }
    }

    button,
    a {
      background-color: #000000;
      // border: 1px solid #aea25d;
      color: blanchedalmond;
      font-size: 16px;
      padding: 20px 8px 20px;
      border-radius: 5px;
      letter-spacing: 1px;
      line-height: 1.1;
      display: block;
      text-decoration: none;
      position: relative;
      overflow: hidden;
      text-align: center;
      width: 100%;
      font-family: "BudmoJiggler-Regular";
      border: transparent;

      &:hover {
        border-color: #523664;
      }

      img {
        display: block;
        margin: 0 auto 15px;
        height: 50px;
        width: 50px;
        background-color: rgba(0, 0, 0, 0.169);
        object-fit: contain;
      }

      span {
        display: block;
      }
    }
  }
}

// Connect Wallet css End
.informationMU {
  .benefitcontent {
    border: 1px solid dimgrey;
    padding: 20px;
  }

  .infotitle {
    margin-bottom: 30px;

    h2 {
      font-size: 48px;
    }
  }

  .infoimage {
    img {
      max-height: 500px;
    }
  }

  .infotxt {


    h3 {
      font-size: 42px;
      margin-bottom: 30px;
      text-align: center;
      color: #fff;
      text-transform: uppercase;
      text-shadow: 0 0 7px #0000, 0 0 0 #fff, 0 0 2px #fff, 0 0 2px #bc13fe,
        0 0 2px #bc13fe, 0 0 0 #bc13fe, 0 0 2px #bc13fe, 0 0 11px #bc13fe;
      animation: pulsate 1.5s infinite alternate;
      font-weight: 400;
      font-family: 'Black Ops One', cursive;
    }

    p {
      letter-spacing: 1px;
      color: #c9c9c9;
      text-align: justify;
      font-size: 15px;

      span {
        color: #ffffff;
        font-weight: 600;
        font-size: 19px;
      }
    }
  }

}

.account {
  display: flex;
  justify-content: center;
}


.Toastify__progress-bar-theme--light {
  background: #ce86f9;
}

.Toastify__toast {
  color: #000000;
  font-size: 18px;
}

.bgVideoBody {
  object-fit: cover;
  background-color: black;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
  opacity: 0.1;
  mix-blend-mode: difference;

  &,
  video {
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.countDownMu {
  text-align: center;
  margin: 40px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 42px;
  line-height: 1;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0 0 7px #0000, 0 0 0 #fff, 0 0 2px #fff, 0 0 2px #bc13fe,
    0 0 2px #bc13fe, 0 0 0 #bc13fe, 0 0 2px #bc13fe, 0 0 11px #bc13fe;
  animation: pulsate 1.5s infinite alternate;
  font-weight: 400;
  font-family: "BudmoJiggler-Regular";

  b {
    display: flex;
    text-align: center;
    background-color: #ffffff;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-size: 20px;
    margin: 0px 10px;
    border-radius: 6px;
    position: relative;

    span {
      position: absolute;
      top: -25px;
      left: 50%;
      transform: translateX(-50%);
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      font-family: "Sorts Mill Goudy", serif;
    }
  }

  p {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 15px auto 0px;
    text-align: center;
    font-size: 16px;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;

    // span {
    //     display: block;
    // }
  }
}

// loader-css
.loaderMain {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

//news section
// .newsbox{
//   position: relative;
//   border: 1px solid #43394d;
//   margin: 0 20px;
//   height: 100%;
//   padding: 30px;
//   border-radius: 10px;
//   background-color: #17092d75;
//   background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.0509803922) 0%, rgba(255, 255, 255, 0) 73.86%);
//   border-style: solid;
//   border-width: 1px 1px 0px 1px;
//   // border-color: rgba(255, 255, 255, 0.1019607843);
//   border-color: rgb(0 0 0 / 10%);
//   border-radius: 16px 16px 0px 0px;
//   width: calc(100% - 20px);
// }
.newsbox {
  position: relative;
  border: 1px solid #43394d;
  margin: 0 20px;
  height: 100%;
  padding: 30px;
  border-radius: 10px;
  background-color: rgba(23, 9, 45, 0.4588235294);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: 16px 16px 16px 16px;
  width: calc(100% - 20px);
  box-shadow: inset 0 0 35px 3px rgb(80 50 130);
}

.roundbg {
  position: relative;
  height: 90px;
  width: 120px;
  margin: 0 auto;

  img {
    position: relative;
    filter: drop-shadow(2px 4px 6px black);

  }
}

.label1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: rgba(58, 3, 158, 0.3);
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 5px 5px 5px #000;
}

.infolabel {
  margin-top: 20px;

  .btnMU,
  .btn-btnMU {
    font-size: 16px;
  }
}

.news,
.nftBalance {
  h3 {
    font-size: 42px;  
    margin-bottom: 20px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    text-shadow: 0 0 7px #0000, 0 0 0 #fff, 0 0 2px #fff, 0 0 2px #bc13fe,
      0 0 2px #bc13fe, 0 0 0 #bc13fe, 0 0 2px #bc13fe, 0 0 11px #bc13fe;
    animation: pulsate 1.5s infinite alternate;
    font-weight: 400;
  }
}

.newssec {
  .alice-carousel__dots-item:not(.__custom) {
    background-color: #ffffff;
    border-radius: 2px;
    height: 15px;
    transform: skew(-10deg);
    width: 15px;
    opacity: 0.1;
  }

  .alice-carousel__dots-item:not(.__custom):hover,
  .alice-carousel__dots-item:not(.__custom).__active {
    background-color: #711dff;
    opacity: 1;
  }

  .alice-carousel__prev-btn-item,
  .alice-carousel__next-btn-item {
    display: inline-flex;
    cursor: pointer;
    // padding: 10px;
    margin: 0;
    color: #fff;
    // font-size: 16px;
    // background: #23143b;
    // border-radius: 50%;
    // height: 40px;
    // width: 40px;
    // justify-content: center;
    font-weight: bolder;

    span {
      height: 40px;
      width: 40px;
      justify-content: center;
      border-radius: 50%;
      align-items: center;
      display: flex;
      font-size: 16px;
      background: #23143b;
    }
  }

  .alice-carousel__prev-btn,
  .alice-carousel__next-btn {
    display: inline-block;
    box-sizing: border-box;
    width: fit-content;
    padding: 10px 5px;
    position: absolute;
    right: 0;
    top: -71px;
  }

  .alice-carousel__prev-btn {
    text-align: right;
    right: 50px;
  }

  .alice-carousel__prev-btn-item:hover,
  .alice-carousel__next-btn-item:hover {
    color: unset;
  }

  @media (max-width:576px) {

    .alice-carousel__prev-btn,
    .alice-carousel__next-btn {
      display: none;
    }
  }
}

// Media Query Css Start
@import "media"; // Media Query Css End



.coin {
  background-color: #000;
  border-radius: 6px;
  position: relative;
  padding: 15%;
  margin: 0 30%;

  &::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    height: calc(100% + 4px);
    width: calc(100% + 4px);
    background: linear-gradient(60deg,
        #ffb46a,
        #ff9984,
        #ff84a7,
        #f09eff,
        #97baff,
        #7bedff,
        #93fff1,
        #a1ffb8);
    border-radius: 6px;
    z-index: -1;
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
  }

  .inbx {
    position: absolute;
    margin-top: 0;
  }
}

.infotxt h4 {
  text-align: center;
  margin-bottom: 20px;

}

.inputvalue {
  margin: 10px;

  .value {
    margin: 5px;
    padding: 7px;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    background: #fff;
    border: 3px solid #fff;
    border-radius: 7px;
    width: 70%;

    .value:hover {
      border: 3px solid #fff;
    }
  }
}

.MuiTabs-scrollButtonsDesktop {
  display: flex !important;
}

.MuiTabs-flexContainer {
  display: flex;
  // justify-content: center;
}

.MuiTab-textColorSecondary {
  color: #fff !important;
}

.MuiTab-textColorSecondary.Mui-selected {
  color: #fff !important;
}

.MuiTabs-indicator {
  background-color: #ffffff !important;
}

.MuiTab-textColorSecondary.Mui-selected {
  color: #fff !important;
}

.tabbar {
  margin-bottom: 32px !important;
}

.input-wrapper {
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 4px;
}

.value {
  flex: 1;
  border: none;
  outline: none;
  padding: 8px;
}

.copy-button {
  // background-color: #f0f0f0;
  border: #fff;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputbar {
  font-size: 19px;
  margin: 0 auto;

  border-radius: 11px;
  margin-top: -7px;
  padding: 5px;
  // border: 2px solid #fff;
  background: #54575a;
}

.form-select {
  width: 70%;
  display: inline;
  margin: 10px;
  filter: hue-rotate(45deg);

  option {
    background: #000 !important;
    color: #fff;
  }
}
.dilogwrap{
  margin: 0 auto;
  max-width: 280px;
  .maxbtn {
    right: 5px;
  }
}
.dilog {
  border: 1px solid #e8e1e1;
  border-radius: 6px;
  padding: 8px;
  width: 100%;
  position: relative;

  h3 {
    font-family: "BudmoJiggler-Regular";
    font-weight: 700;
    line-height: 1;
    color: #fff;
  }
}

.form-select2 {
  width: 70%;
  display: block;
  margin: 10px auto;
  filter: hue-rotate(45deg);
  /* margin-left: 25%; */
  width: 100%;
  padding: 9px;
  border-radius: 6px;
  max-width: 280px;

  option {
    padding: 0 !important;
    background: #000 !important;
    color: #fff !important;
  }
}

.inputvalue2 {
  margin: 3px;
}

.stakebtn2 {
  padding: 5px 5px;
  border-radius: 6px;
  text-decoration: none;
  text-align: center;
  z-index: 9999999;
  color: white;
  font-weight: bolder;
  text-transform: uppercase;
  position: relative;
  font-size: 12px;
  margin-left: 26%;
  width: 48%;
}

.stakebtn2::after {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  height: calc(100% + 8px);
  width: calc(100% + 8px);
  background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
  border-radius: 6px;
  z-index: -1;
  background-size: 300% 300%;
}

.bgGIF {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: -3;
  top: 0;
  left: 0;
  max-width: 100%;
  background-size: cover;
}




.logo {

  user-select: none;
  margin-bottom: 30px;
  text-align: left;
}

.logo b {
  font-size: 50px;
  color: #fee;
  text-shadow: 0 -40px 100px, 0 0 2px, 0 0 1em #5411a1, 0 0 0.5em #5411a1, 0 0 0.1em #5411a1, 0 10px 3px #000;
}

.logo b span {
  animation: blink linear infinite 2s;
}

.logo b span:nth-of-type(2) {
  animation: blink linear infinite 3s;
}

@keyframes blink {
  78% {
    color: inherit;
    text-shadow: inherit;
  }

  79% {
    color: #333;
  }

  80% {

    text-shadow: none;
  }

  81% {
    color: inherit;
    text-shadow: inherit;
  }

  82% {
    color: #333;
    text-shadow: none;
  }

  83% {
    color: inherit;
    text-shadow: inherit;
  }

  92% {
    color: #333;
    text-shadow: none;
  }

  92.5% {
    color: inherit;
    text-shadow: inherit;
  }
}
@keyframes move {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.boxbtn {
  --black: #000000;
  --ch-black: #141414;
  --eer-black: #1b1b1b;
  --night-rider: #2e2e2e;
  --white: #ffffff;
  --af-white: #f3f3f3;
  --ch-white: #a456ff;
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  // color: #ffffff;
  width: 190px;
  font-size: 13px;
  text-decoration: none;
  text-transform: uppercase;
  transition: .5s;
  margin-top: 15px;
  margin-bottom: 20px;
  // letter-spacing: 4px;
  // background: transparent;
  border: none;
  // background: #652fa3;
  color: #fff;
  background-color: var(--ch-black);
  border: transparent;
  --cui-btn-active-bg: transparent;
  cursor: pointer;
  font-weight: 700;
  transition-duration: .2s;
  border-radius: 10px;
  // box-shadow: inset 1px 1px 8px #5411a1;
 
}
.boxbtn::before {
  content: '';
  position: absolute;
  left: -2px;
  top: -2px;
  border-radius: 10px;
  background: linear-gradient(45deg, 
  var(--ch-black), var(--eer-black),
  var(--night-rider), var(--ch-white), var(--night-rider), 
	var(--eer-black), var(--ch-black),var(--ch-black));
  background-size: 400%;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: -1;
  animation: move 20s linear infinite;
  
 
}
.boxbtn:disabled::before{
  animation: none;
}

.boxbtn:after {
  filter: blur(50px);
}
@keyframes steam {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}




// .boxbtn:hover {
//   background: transparent;
//   color: #fff;
//   border-radius: 5px;
//   box-shadow: 0 0 5px #5411a1,
//     0 0 25px #5411a1,
//     0 0 50px #5411a1,
//     0 0 100px #5411a1;

// }

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }

  50%,
  100% {
    left: 100%;
  }
}

.boxbtn2 {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  // color: #ffffff;
  width: 193px;
  font-size: 13px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  margin-top: 15px;
  margin-bottom: 20px;
  letter-spacing: 4px;
  // background: transparent;
  border: none;
  background: #652fa3;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #5411a1,
    0 0 25px #5411a1,
    0 0 50px #5411a1,
    0 0 100px #5411a1;


}

.boxbtn2:hover {
  background: transparent;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #5411a1,
    0 0 25px #5411a1,
    0 0 50px #5411a1,
    0 0 100px #5411a1;
}


.wnbtn {
  background-color: #bc4242;
  padding: 10px;
  border-Radius: 20px;
  cursor: pointer;
  margin-right: 4px;
}

.mobilebtn {
  display: none;
}

.wnbtn2 {
  background-color: #0763af;
  cursor: pointer;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  padding: 5px 10px;
  text-align: center;
  align-items: center;
  margin: 0 auto;
  width: fit-content;
  margin-top: 10px;
}





.wnbtn2::marker {
  color: white;
}




// -----------------------F&Q section----------------------- //


.card {
  width: 100% !important;
  height: 100%;
  gap: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  // flex-basis: calc(50% - 18px);
}



.title {
  font-size: 18px;
  /* text-lg */
  text-align: center;
}

@media (min-width: 768px) {

  /* md: breakpoint */
  .title {
    font-size: 20px;
    /* text-xl */
  }
}

.description {
  color: #E2E8F0;
  /* text-gray-200 */
  text-align: center;
}

.dot {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  color: #fff;
  border: 2px solid #5411a1;
  font-size: 20px;
  display: flex;
  text-align: center;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  font-family: "Chakra Petch", sans-serif;
  box-shadow: inset 0 0 30px #5411a1;
}

.fqbox {
  padding: 22px;
}

.test {
  border-radius: 8px;
  border: 1px solid #5411a1;
  margin: 8px;
  flex: 0 0 calc(33.33% - 20px);
  background-color: #000;
}

.answer {
  padding-top: 8px;
  font-size: 15px;
  color: #b5bfcdae;
}

.boxborder1 {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 600;
  justify-content: center;
  width: 300px;
  height: 100px;
  padding: 50px;
  border: 3px solid #652fa3;
  margin: 10px;
  animation: mymove 5s infinite;
}

.boxborder2 {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 600;
  justify-content: center;
  width: 300px;
  height: 100px;
  padding: 50px;
  border: 3px solid #652fa3;
  margin: 10px;
  animation: mymove 5s infinite;
}

.boxborder3 {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 600;
  justify-content: center;
  width: 300px;
  height: 100px;
  padding: 50px;
  border: 3px solid #652fa3;
  margin: 10px;
  animation: mymove 5s infinite;
}

@keyframes mymove {
  50% {
    border-radius: 50px;
  }
}


.hello {
  .MuiTabs-flexContainer {
    justify-content: center;

  }

  .Mui-selected {
    border-radius: 8px;
    color: #fff !important;
    background: #652fa3 !important;
  }

  .MuiTabs-indicator {
    display: none !important;
  }
}

.box {
  display: flex;
  margin: 0 auto;
  justify-content: center;
}

.boxbutton {
  h4 {
    font-size: 10px;
  }

  background-color: red;

  .boxbutton:hover {
    background: red;
  }
}


.boxttn {
  color: #fff;
}

.statushighlight {
  margin: 0 auto;
  padding-left: 5px;
  padding-right: 5px;
  width: fit-content;
  border-radius: 4px;
  background: #5411a1;
}


.myPagination {
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 20px;
  width: fit-content;
  background-color: #ffffff73;
}


.maxbtn {
  position: absolute;
  right: 50px;
  top: 50%;
  padding: 5px;
  transform: translate(0%, -50%);
  border-radius: 6px;
  color: #652fa3 !important;
  font-weight: bolder !important;
  cursor: pointer;
}
.marquee {
  font-size: 18px;
  color: #fff;
  height: 40px;
  overflow: hidden;
  background-color: transparent;
  position: relative;
  line-height: 2.2;
  margin-bottom: 15px;
  padding: 0px;
  letter-spacing: 2px;
  font-weight: 400;
  display: block;
  background-image: linear-gradient(to right, #ffffff00, #E900C2, #ffffff00);
  text-transform: uppercase;
  &::before {
    content: "";
    background-image: url(../images/marqueemarketing.gif);
    display: block;
    width: 45px;
    height: 100%;
    background-size: contain;
    position: absolute;
    left: 5px;
    top: 0;
    background-repeat: no-repeat;
    z-index: 2;
  }
  &::after {
    content: "";
    background-image: linear-gradient(to right, #121212 70%, transparent);
    display: block;
    width: 100px;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0;
    z-index: 1;
  }
  b {
    color: #FFD196;
  }
  img {
    display: inline-block;
    width: 30px;
    -o-object-fit: contain;
    object-fit: contain;
    position: relative;
    animation: zoom-in-zoom-out 1s ease-out infinite;
    height: 26px;
  }
  marquee {
    position: relative;
    z-index: 1;
  }
  @keyframes zoom-in-zoom-out {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}