@media only screen and (max-width: 992px) {
   
    .test {
        flex-basis: calc(50% - 20px) !important;
    }

    .logo {
        text-align: center !important;
    }

    .boxbtn {
        width: 180px !important;
        font-size: 12px !important;
    }

    .container,
    .container-sm {
        // max-width: calc(100% - 20px);
    }

    .lazyload-wrapper {
        max-width: 100%;
        width: 100%;
        height: auto;
        // margin: 18%;

    }

    .topInformation h1 {
        font-size: 35px;
        text-align: center;
    }

    .buttonGroup {
        justify-content: center;
    }

    // .informationMU .infotxt h3 {
    //     font-size: 32px;
    // }

    .css-m9glnp-MuiPaper-root-MuiDialog-paper {
        width: 80% !important;
        height: 50% !important;
    }

    .listViewBx .inrInfoBx .imginrBx::before {
        background-size: 150%;
    }
}

@media only screen and (max-width: 767px) {
    .form{
        margin: 0 auto ;
    }
 
    .test {
        flex-basis: calc(100%) !important;
    }

    .boxbtn {
        width: 180px !important;
        font-size: 12px !important;
    }

    header .logoMainHead {
        width: 75px;
    }

    .btnMU,
    .btn-btnMU {
        font-size: 14px;
    }

    .informationMU .infotxt {
        text-align: center;
    }

    .informationMU .infotxt p {
        font-size: 13px;
    }

    .listViewBx .inrInfoBx .imginrBx::before {
        background-size: 70%;
    }

    .nftMain .stakebtn {
        padding: 4px 7px;
        border-radius: 9px;
    }

    .countDownMu p {
        font-size: 14px;
    }

    .listViewBx .inrInfoBx img {
        // max-width: 130px;
    }

    .footer_bottom_inner {
        flex-direction: column;
        text-align: center;
    }

    .copyright_right {
        margin-bottom: 12px;
    }

    // .nftBalance .balanceViewBx .balancelist {
    //     padding: 23px;
    // }
    // .nftMain {
    //     padding: 20px 0px;
    // }
}

@media (max-width: 575px) {
         
        .hello{
            .MuiTabs-flexContainer{
                justify-content: unset !important;
    
            }
        }
    .box {
        flex-direction: column;
        align-items: center;
    }

    .test {
        flex-basis: calc(100%) !important;
    }

    .wnbtn {
        display: none;
    }

    .mobilebtn {
        background-color: #bc4242;
        padding: 10px;
        border-Radius: 20px;
        cursor: pointer;
        margin-right: 4px;
        display: block !important;
        margin: 0 auto;
    }
    .informationMU {
        .fqbox {
            padding: 0px;
        }
    }

    //   .dollar{
    //     margin-right: -7px !important;
    //   }

    .logo b {
        font-size: 26px !important;
    }

    .boxbtn2 {
        width: 154px !important;
        font-size: 13px !important;
    }

    .popular_gaming_inner .swiper-button-next,
    .popular_gaming_inner .swiper-button-prev {
        height: 35px;
        width: 35px;
    }

    .nftMain .nftheadline {
        font-size: 35px;
    }

    .topInformation h1 {
        font-size: 35px;
    }

    .news h3 {
        font-size: 35px;
    }

    .nftBalance h3 {
        font-size: 35px;
    }

    .informationMU .infotxt h3 {
        font-size: 35px;
    }

    .topInformation .btnMU,
    .topInformation .btn-btnMU {
        font-size: 14px;
        // min-width: 150px;
    }

    .listViewBx.ms\:mt-5.pt-5 {
        padding-top: 0 !important;
    }

    .listViewBx .inrInfoBx .imginrBx::before {
        background-size: 44vw;
    }


    .nftBalance .balanceViewBx ul li {
        margin-bottom: 15px;
        font-size: 14px;
    }

    main section {
        margin-bottom: 30px;
    }

    .connectWalletToggle .popupbtngrp button,
    .connectWalletToggle .popupbtngrp a {
        font-size: 14px;
    }

    .footer_bottom_link_menu ul li {
        margin-right: 0;
        padding-right: 8px;
    }

    .footer_social ul li a {
        width: 22px;
        height: 22px;
    }

    footer .copyright span {
        display: block;
        margin-top: 4px;
    }

    footer .footer {
        padding: 35px 0px 15px;
    }

    .connectWalletToggle .modal-content .modal-body {
        padding: 0px;
    }

    .connectWalletToggle .modal-content .modal-header {
        padding: 20px 0px;
    }

    header .header-brand img {
        max-width: 155px;
    }

    .topInformation h1 .lstwld {
        display: block;
    }

    .topInformation {
        margin-bottom: 20px;
    }

    .countDownMu {
        font-size: 35px;

        p {
            font-size: 13px;

            span {
                display: block;
            }
        }
    }

    footer .social-icons a {
        width: 40px;
        height: 40px;
        margin: 0px 5px !important;
    }

}

@media only screen and (max-width: 390px) {
    .nftBalance .balanceViewBx ul li {
        font-size: 12px;
    }

    .topInformation .btnMU,
    .topInformation .btn-btnMU {
        min-width: 105px;
    }

    .header {

        .btnMU,
        .btn-btnMU {
            font-size: 15px;
            svg {
                font-size: 1rem;
            }
        }
    }

    .listViewBx .inrInfoBx .imginrBx::before {
        background-size: 60vw;
    }

    .listViewBx .inrInfoBx img {
        max-width: 70px;
    }

    .connectWalletToggle .popupbtngrp button img,
    .connectWalletToggle .popupbtngrp a img {
        height: 40px;
        width: 40px;
    }

    .connectWalletToggle .popupbtngrp button,
    .connectWalletToggle .popupbtngrp a {
        font-size: 12px;
        padding: 10px 4px 10px;
    }

    .connectWalletToggle .modal-content .modal-header {
        padding: 15px 10px;
    }

    .connectWalletToggle .modal-content .modal-title {
        font-size: 18px;
    }

    header .header-brand img {
        max-width: 125px;
    }

    .header {

        .btnMU span,
        .btn-btnMU span {
            display: block;
            padding: 0px 6px;
            font-size: 10px;
        }
    }

    main {
        padding: 15px 0px;
    }

}



@media only screen and (min-width:767px) {
   
    .popular_gaming_inner>.swiper .swiper-slide.swiper-slide-next {
        transform: scale(1.2);
        position: relative;
        z-index: 9
    }

    .popular_gaming_inner>.swiper {
        transform: scale(.95);
        padding-top: 60px;
        padding-bottom: 90px;
        padding-left: 30px;
        padding-right: 30px
    }
}